<template>
    <v-container>
        <base-material-card icon="mdi-account-multiple" title="Categoría de cliente" class="px-5 py-3">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="Nombre" v-model="categoria.nombre" :error-messages="errors.nombre" class="required">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-btn color="primary" @click="save()" :loading="loading">Guardar</v-btn>
                    <v-btn @click="$router.push({name: 'ClienteCategoria'})" :loading="loading">Cancelar</v-btn>
                </v-col>
            </v-row>
        </base-material-card>
    </v-container>
</template>
<script>
    const MODEL = {
        nombre: null,
        codigo: null
    }
    export default {
        data: () => ({
            categoria: {},
            errors: {},
            loading: false
        }),
        mounted() {
            if (this.$route.params.id) {
                this.load()
            }
        },
        methods: {
            load() {
                this.$http.get(`/clientes/categorias/${this.$route.params.id}`).then(response => {
                    this.categoria = response.data
                })
            },
            save() {
                this.loading = true
                this.errors = {}
                if (this.$route.params.id) {
                    this.update()
                    return
                }
                this.create()
            },
            create() {
                this.$http.post('/clientes/categorias', this.categoria).then(response => {
                    this.$router.push({name: 'ClienteCategoria'})
                }).catch(error => {
                    switch (error.response.status) {
                        case 422:
                            this.errors = this.errors = error.response.data.errors
                            break;

                        default:
                            break;
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            update() {
                this.$http.post(`/clientes/categorias/${this.$route.params.id}`, this.toFormData(this.categoria, 'PUT')).then(response => {
                    this.$router.push({ name: 'ClienteCategoria' })
                }).catch(error => {
                    switch (error.response.status) {
                        case 422:
                            this.errors = error.response.data.errors
                            break;

                        default:
                            break;
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            toFormData(object, method) {
                let formData = new FormData

                if (method) {
                    formData.append('_method', method)
                }

                for (const key in object) {
                    if (object.hasOwnProperty(key)) {
                        const element = object[key];
                    }
                    if (object[key]) {
                        if (Array.isArray(object[key])) {
                            object[key].forEach(subobject => {
                                formData.append(`${key}[]`, subobject)
                            })
                        } else {
                            formData.append(key, object[key])
                        }
                    }
                }
                return formData
            }
        }
    }
</script>